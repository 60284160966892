import { Box, Button, CircularProgress } from '@material-ui/core';
import { Unarchive } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import sweetAlert from '../../../../f5/utils/sweetAlert';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import axios_instance from '../../../../services/auth/jwt/config';
import SharedTitle from './SharedTitle';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  confirmButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
  denyButton: {
    color: 'white',
    backgroundColor: '#d92916',
    '&:hover': {
      backgroundColor: '#f55538',
    },
  },
}));

export default function ArchiveOcurrence({ ocurrence, closeIt }) {
  const [loading, setLoading] = useState({ message: '', status: false });
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading({ status: true, message: 'Arquivando ocorrência...' });
    axios_instance.get(`ouvidoria/ocorrencia/arquivar/${ocurrence.ocorrencia_id}`).then(() => {
      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      setLoading({ status: false, message: '' });
      sweetAlert('success', 'Sucesso', 'Ocorrência arquivada com sucesso!');
    });
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<Unarchive />} title="Arquivar Ocorrência" />
      <Box my={8} fontWeight={500} fontSize={16} textAlign="center">
        Tem certeza que deseja arquivar esta ocorrência?
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="center">
        <Box mx={2}>
          <Button classes={{ root: classes.confirmButton }} onClick={handleSubmit}>
            Sim
          </Button>
        </Box>
        <Box mx={2}>
          <Button classes={{ root: classes.denyButton }} onClick={() => closeIt()}>
            Não
          </Button>
        </Box>
      </Box>
    </div>
  );
}
