import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Box, TextField, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import AccountAlerts from './AccountAlerts';
import accountService from '../../services/f5-services/accountService';

const useStyles = makeStyles(theme => ({
    saveButton: {
        fontSize: 12,
        color: 'white',
        backgroundColor: '#0bb511',
        '&:hover': {
            backgroundColor: '#1fc225',
        },
    },
}));

const ChangeInfo = ({ data, updateUser }) => {
    const [name, setName] = useState(data.nome ? data.nome : '');
    const [phone, setPhone] = useState(data.telefone ? data.telefone : '');
    const [cellphone, setCellphone] = useState(data.celular ? data.celular : '');
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');

    const classes = useStyles();

    const handleUserinfo = async () => {
        const formData = {
            celular: phone,
            nome: name,
            telefone: cellphone,
        };

        if (name) {
            const res = await accountService.changeUserInfo(formData);
            if (res.success) {
                updateUser();
                setAlertMessage('Dados alterados com successo.');
                setAlertSeverity('success');
                setAlert(true);
            } else {
                setAlertMessage('Falha no envio de informações. Checar campos obrigatórios.');
                setAlertSeverity('error');
                setAlert(true);
            }
        }
    };

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box fontWeight={700} fontSize={12} mb={8}>
                Alterar dados cadastrais
            </Box>
            <Box mb={4} px={4}>
                <TextField variant="outlined"
                    required
                    fullWidth
                    size="small"
                    id="user-name-input"
                    label="Nome"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Box>
            <Box mb={4} px={4}>
                <InputMask mask="(99)9999-9999" value={phone} onChange={e => setPhone(e.target.value)}>
                    <TextField variant="outlined" fullWidth size="small" id="user-phone-input" label="Telefone" />
                </InputMask>
            </Box>
            <Box mb={4} px={4}>
                <InputMask mask="(99)99999-9999" value={cellphone} onChange={e => setCellphone(e.target.value)}>
                    <TextField variant="outlined" fullWidth size="small" id="user-cellphone-input" label="Celular" />
                </InputMask>
            </Box>
            <Box mt={2} display="flex" justifyContent="end">
                <Button
                    disabled={!name}
                    variant="contained"
                    classes={{ root: classes.saveButton }}
                    size="small"
                    onClick={handleUserinfo}>
                    Salvar
                </Button>
            </Box>
            <AccountAlerts message={alertMessage} open={alert} setOpen={setAlert} severity={alertSeverity} />
        </Box>
    );
};

export default ChangeInfo;
