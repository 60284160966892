import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, Button, TextField, CircularProgress } from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  textTitle: {
    fontWeight: 700,
  },
  buttonFinish: {
    color: 'white',
    backgroundColor: '#0fa31b',
    '&:hover': {
      backgroundColor: '#1db82a',
    },
  },
}));

const ReturnOcurrence = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [note, setNote] = useState('');
  const [justify, setJustify] = useState('');
  const [inputFile, setInputFile] = useState('');
  const [inputFileData, setInputFileData] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (note) {
      let formData = new FormData();
      if (inputFileData) {
        setLoading({ status: true, message: 'Fazendo upload de arquivo...' });
        formData.append('anexo', inputFileData);
        formData.append('ocorrencia_id', ocurrence.ocorrencia_id);
        formData.append('file_observacao', note);
        await ocurrencesActionService.sendActionFile(formData);
      }

      setLoading({ status: true, message: 'Enviando resposta...' });

      const postData = {
        descricao: JSON.stringify({
          compete: 'N',
          envolvedepto: 'S',
          justificativa: justify,
        }),
        observacao: note,
        ocorrencia_id: ocurrence.ocorrencia_id,
      };

      await ocurrencesActionService.sendReturnOcurrence(postData);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      setLoading({ status: false, message: '' });
      sweetAlert('success', 'Sucesso', 'Resposta enviada com sucesso!');
    }
  };

  const handleFileChange = event => {
    setInputFileData(event.target.files[0]);
    setInputFile(event.target.files[0].name);
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<UndoIcon />} title="Não Compete a este Departamento" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box px={4} mb={4}>
        <Box width={'100%'}>
          <TextField
            label="Justificativa"
            required
            fullWidth
            variant="outlined"
            size="small"
            value={justify}
            onChange={e => setJustify(e.target.value)}
          />
        </Box>
      </Box>

      <Box px={4}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box mb={4} display="flex" alignItems="center" justifyContent="center">
            <Button variant="contained" component="label">
              Anexar Arquivo
              <input
                id="quick-answer-file"
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.gif,.png,.xls,.xlsx,.csv"
              />
            </Button>
            <Box ml={2} fontWeight={700} fontSize={12}>
              {inputFile}
            </Box>
          </Box>
          <Box width={'100%'}>
            <TextField
              label="Observação"
              required
              fullWidth
              variant="outlined"
              size="small"
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={4}>
        <Button variant="contained" onClick={handleSubmit} classes={{ root: classes.buttonFinish }}>
          Finalizar
        </Button>
      </Box>
    </div>
  );
};

export default ReturnOcurrence;
