import React from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import AccountPage from '../../../f5/account/AccountPage';

const Account = () => {
    return <PageContainer>
        <AccountPage />
    </PageContainer>
};

export default Account;
