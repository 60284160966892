import React, { useEffect, useState } from 'react';
import ocurrencesService from '../../../../services/f5-services/ocurrencesService';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import OcurrenceDetails from '../../../../f5/ocurrences/OcurrenceDetails';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const ViewOcurrence = () => {
  const ocurrence = useSelector(({ ocurrences_actions }) => ocurrences_actions.selectedOcurrence);
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const [masterFetched, setMasterFetched] = useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();
  let history = useHistory();

  // TODO: Para melhorar performance, expor um endpoint de checagem.
  useEffect(() => {
    const denyView = () => {
      history.push(`/conta`);
    };

    const checkData = async () => {
      if (userAccess === 'ouv_master') {
        const extendData = await ocurrencesService.getExtendOcurrences();
        const extendAcceptedData = await ocurrencesService.getExtendAcceptedOcurrences();
        const extendRejectedData = await ocurrencesService.getExtendRejectedOcurrences();

        const isValid = [...extendData.caixa, ...extendAcceptedData.caixa, ...extendRejectedData.caixa].find(
          item => item.ocorrencia_id === parseInt(id),
        );

        if (!isValid) {
          denyView();
        }

        setMasterFetched(true);
      }
    };

    checkData();
  }, [userAccess, id, history, setMasterFetched]);

  useEffect(() => {
    dispatch(selectOcurrence(id));
  }, [dispatch, id]);

  const loader = () => {
    return (
      <Box width="100%" height={150} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  };

  // Toda essa lógica de fetch é para garantir que o ouv_master só possa ver ocorrências com prorrogação, mesmo se for um id válido na url.
  if (userAccess === 'ouv_master' && masterFetched) {
    return (
      <PageContainer>
        {ocurrence && ocurrence.ocorrencia_id === parseInt(id) ? <OcurrenceDetails ocurrence={ocurrence} /> : loader()}
      </PageContainer>
    );
  } else if (userAccess === 'ouv_master') {
    return <PageContainer>{loader()}</PageContainer>;
  }

  return (
    <PageContainer>
      <OcurrenceNavbar />
      {ocurrence && ocurrence.ocorrencia_id === parseInt(id) ? <OcurrenceDetails ocurrence={ocurrence} /> : loader()}
    </PageContainer>
  );
};

export default ViewOcurrence;
