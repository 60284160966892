import MaterialTable from '@material-table/core';
import { Add, Delete, Edit, Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';

interface TipoIndexProps {

}

type Tipo = {
    id: number,
    nome: string,
    descricao: string
}

export default function TipoIndex({ ...props }: TipoIndexProps) {
    const [tipos, setTipos] = useState<Tipo[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [editing, setEditing] = useState<Tipo>({
        id: 0,
        nome: '',
        descricao: ''
    })

    const onLoad = () => {
        setLoading(true)
        axios_instance.get<{ success: boolean, data: Tipo[] }>(`ordem_servico/tipo/index`).then((response) => {
            let r = response.data
            if (r.success) {
                setTipos(r.data)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao carregar os tipos de serviço')
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        onLoad()
    }, [])

    const onDelete = (rowData: any) => {
        setSending(true)
        axios_instance.delete<{ success: boolean, message: string }>(`ordem_servico/tipo/${rowData.id}`).then((response) => {
            let r = response.data
            if (r.success) {
                onLoad()
                sweetAlert('success', 'Sucesso', r.message)
            } else {
                sweetAlert('error', 'Erro', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao excluir o tipo de serviço')
        }).finally(() => {
            setSending(false)
        })
    }

    const onSave = () => {
        setSending(true)
        setOpenModal(false)
        axios_instance.post<{ success: boolean, message: string }>(`ordem_servico/tipo/save${editing.id === 0 ? '' : `/${editing.id}`}`, {
            nome: editing.nome,
            descricao: editing.descricao
        }).then((response) => {
            let r = response.data
            if (r.success) {
                onLoad()
                sweetAlert('success', 'Sucesso', r.message)
            } else {
                sweetAlert('error', 'Erro', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao salvar o tipo de serviço')
        }).finally(() => {
            setSending(false)
        })
    }

    // @ts-ignore
    return <PageContainer>
        <MaterialTable
            title="Tipos de Serviço"
            columns={[
                { title: 'Nome', field: 'nome' },
            ]}
            data={tipos}
            actions={[
                {
                    isFreeAction: true, icon: () => <Add />, tooltip: 'Adicionar', onClick: () => {
                        setOpenModal(true)
                        setEditing({
                            id: 0,
                            nome: '',
                            descricao: ''
                        })
                    }
                }, {
                    icon: () => <Edit />, tooltip: 'Editar', onClick: (event, rowData) => {
                        setOpenModal(true)
                        setEditing({
                            // @ts-ignore
                            id: rowData.id,
                            // @ts-ignore
                            nome: rowData.nome,
                            // @ts-ignore
                            descricao: rowData.descricao
                        })
                    }
                }, {
                    icon: () => <Delete />, tooltip: 'Excluir', onClick: (event, rowData) => {
                        sweetAlert('warning', 'Atenção', 'Deseja realmente excluir este tipo de serviço?', () => {
                            onDelete(rowData)
                        })
                    }
                }
            ]}
            localization={tableLocalization}
            icons={tableIcons}
            isLoading={loading}
        />
        <Backdrop open={sending} style={{ zIndex: 9999 }}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" color="white">
                <CircularProgress />
                <Typography variant="h6" style={{ marginLeft: 10 }}>Enviando...</Typography>
            </Box>
        </Backdrop>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle>
                {editing.id === 0 ? 'Adicionar' : 'Editar'} Tipo de Serviço
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Nome"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    value={editing.nome}
                    onChange={(e) => setEditing({ ...editing, nome: e.target.value })}
                />
                <TextField
                    label="Descrição"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    value={editing.descricao}
                    minRows={3}
                    onChange={(e) => setEditing({ ...editing, descricao: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button variant="contained" color="secondary" onClick={() => setOpenModal(false)}>Cancelar</Button>
                    <Button
                        variant="contained"
                        startIcon={<Save />}
                        style={{ backgroundColor: '#4caf50', color: 'white' }} onClick={onSave}
                    >
                        Salvar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </PageContainer>
}