import React, { useEffect, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { Edit, RotateLeft, Save } from '@material-ui/icons';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';

interface AtendenteIndexProps {

}

type User = {
    id: number,
    nome: string,
    email: string,
    departamento: string,
    password: string,
}

export default function AtendenteIndex({ ...props }: AtendenteIndexProps) {
    const [usuarios, setUsuarios] = useState<User[]>([]);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [changePassword, setChangePassword] = useState(false);
    const [editing, setEditing] = useState<{ open: boolean, data: User }>({
        open: false, data: {
            password: '',
            departamento: '',
            nome: '',
            email: '',
            id: 0,
        }
    });

    const onLoad = () => {
        setLoading(true);
        axios_instance.get<{ success: boolean, data: User[], message: string }>(`atendente/index`).then((response) => {
            let r = response.data;
            if (r.success) {
                setUsuarios(r.data)
            } else {
                sweetAlert('error', 'Erro ao carregar usuários', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro ao carregar usuários', error.message)
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        onLoad()
    }, [])

    const onSave = (data: User) => {
        setSending(true);
        setEditing({
            open: false, data: {
                nome: '',
                departamento: '',
                email: '',
                password: '',
                id: 0,
            }
        });
        if (changePassword && data.password.length < 6) {
            sweetAlert('error', 'Erro ao salvar', 'Senha deve conter no mínimo 6 caracteres')
            setSending(false);
            setEditing({
                open: true, data: data
            });
            return;
        }
        axios_instance.post<{ success: boolean }>(`atendente/save/${data.id}`, {
            nome: data.nome,
            email: data.email,
            change_password: changePassword,
            password: data.password,
        }).then((response) => {
            let r = response.data;
            if (r.success) {
                onLoad()
            }
        }).catch((error) => {
        }).finally(() => {
            setSending(false);
        })
    }
    //@ts-ignore
    return <PageContainer>
        <Backdrop open={sending} style={{ zIndex: 9999 }}>
            <Box display="flex" flexDirection="column" alignItems="center" color="white" gridGap={2}>
                <CircularProgress color="inherit" />
                <Typography variant="h4" color="inherit">Salvando...</Typography>
            </Box>
        </Backdrop>
        <MaterialTable
            title="Usuários"
            columns={[
                { title: 'Nome', field: 'nome' },
                { title: 'E-mail', field: 'email' },
                { title: 'Departamento', field: 'departamento' },
            ]}
            isLoading={loading}
            actions={[
                {
                    icon: () => <Edit />, onClick: (event, rowData) => {
                        //@ts-ignore
                        setEditing({ open: true, data: rowData });
                    },
                    tooltip: 'Editar'
                }, /* {
                    icon: () => <RotateLeft />, onClick: (event, rowData) => {
                    },
                    tooltip: 'Resetar senha'
                } */
            ]}
            options={{
                actionsColumnIndex: -1,
                pageSize: 10,
            }}
            data={usuarios}
            localization={tableLocalization}
            icons={tableIcons}
        />
        <Dialog open={editing.open} onClose={() => setEditing({
            open: false, data: {
                nome: '',
                departamento: '',
                email: '',
                password: '',
                id: 0,
            }
        })} maxWidth="md" fullWidth>
            <DialogTitle>Editar Usuário</DialogTitle>
            <DialogContent>
                <TextField
                    label="Nome"
                    value={editing.data.nome}
                    onChange={(e) => setEditing({ ...editing, data: { ...editing.data, nome: e.target.value } })}
                    fullWidth variant="outlined" margin="normal"
                />
                <TextField
                    label="E-mail"
                    value={editing.data.email}
                    onChange={(e) => setEditing({ ...editing, data: { ...editing.data, email: e.target.value } })}
                    fullWidth variant="outlined" margin="normal"
                />
                <FormControlLabel
                    label="Alterar senha?"
                    control={<Switch />}
                    checked={changePassword}
                    onChange={(e) => setChangePassword((prev) => !prev)}
                />
                <TextField
                    label="Nova Senha"
                    value={editing.data.password}
                    disabled={!changePassword}
                    onChange={(e) => setEditing({ ...editing, data: { ...editing.data, password: e.target.value } })}
                    fullWidth variant="outlined" margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button
                        variant='contained' color="secondary"
                        onClick={() => setEditing({
                            open: false, data: {
                                nome: '',
                                departamento: '',
                                email: '',
                                password: '',
                                id: 0,
                            }
                        })}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => onSave(editing.data)}
                        startIcon={<Save />}
                        style={{ backgroundColor: '#4caf50', color: 'white' }}
                    >
                        Salvar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </PageContainer>
}