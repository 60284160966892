import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { History, Ordem } from './OrderIndex';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';
import moment from 'moment';

interface DialogHistoryOrdemProps {
    open: boolean,
    onClose: () => void,
    id: number | null,
}

export default function DialogHistoryOrdem({ open, onClose, id, ...props }: DialogHistoryOrdemProps) {
    const [history, setHistory] = useState<History[]>([])
    const [ordem, setOrdem] = useState<Ordem | null>(null)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const small = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        if (id) {
            setLoading(true)
            axios_instance.get<{ success: boolean, data: Ordem, message: string }>(`ordem_servico/show/${id}`).then(response => {
                let r = response.data
                if (r.success) {
                    setOrdem(r.data)
                    if (r.data.history) {
                        setHistory(r.data.history)
                    }
                } else {
                    sweetAlert('error', 'Erro', r.message)
                }
            }).catch(error => {
                sweetAlert('error', 'Erro', 'Erro ao buscar histórico da ordem de serviço')
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [id])

    return <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth fullScreen={small}>
        <DialogTitle>
            Histórico da Ordem de Serviço {ordem?.protocolo}
        </DialogTitle>
        <DialogContent>
            {loading ? <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress />
            </Box> : <Box>
                <Box display="flex" flexDirection="column" marginBottom="10px" borderBottom="1px solid #ccc" paddingBottom="10px">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box marginRight="10px" fontWeight="bold">{moment(ordem?.created_at).format('DD/MM/YYYY HH:mm')}</Box>
                        <Box>{ordem?.aberto?.nome}</Box>
                    </Box>
                    <Box>Abriu a Ordem de serviço</Box>
                </Box>
                {history.map((h, index) => <Box key={index} display="flex" flexDirection="column" marginBottom="10px">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box marginRight="10px" fontWeight="bold">{moment(h.created_at).format('DD/MM/YYYY HH:mm')}</Box>
                        <Box>{h.user.nome}</Box>
                    </Box>
                    <Box>{h.message}</Box>
                </Box>)}
            </Box>}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose()} color="secondary">
                Fechar
            </Button>
        </DialogActions>
    </Dialog>
}