import { Box, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { CloudDownload, RotateLeft, RotateRight } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

interface ImagePreviewProps {
    label: string,
    url: string,
}

export default function ImagePreview({ label, url, ...props }: ImagePreviewProps) {
    const [image, setImage] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({ has: false, message: '' })
    const [rotate, setRotate] = useState(0)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (url) {
            //fetch image from api and save it to state
            fetch(`${url}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => res.blob()).then(blob => {
                if (blob.type === 'application/json') {
                    setImage('')
                } else {
                    setImage(URL.createObjectURL(blob))
                }
            }).catch(err => {
                setError({ has: true, message: err.message })
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [url])

    if (loading) {
        return <CircularProgress />
    }
    if (!image) {
        return <div>Sem Anexo</div>
    }
    return <>
        <img
            src={image} alt={label} {...props} style={{ height: 100, width: '100%', objectFit: 'contain', cursor: 'zoom-in' }}
            //baixar imagem
            onClick={() => {
                setOpen(true)
            }}
        />
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
            <DialogContent>
                <img src={image} alt={label} style={{ width: '100%', transform: `rotate(${rotate}deg)` }} />
            </DialogContent>
            <DialogActions>
                <Box display="flex" width="100%" justifyContent="space-between">
                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                setRotate(rotate + 90)
                            }}
                        >
                            <RotateRight />
                        </Button>
                        <Button
                            onClick={() => {
                                setRotate(rotate - 90)
                            }}
                        >
                            <RotateLeft />
                        </Button>
                    </ButtonGroup>
                    <Button
                        onClick={() => {
                            const a = document.createElement('a')
                            a.href = image
                            a.download = `${label}`
                            a.click()
                        }}
                        startIcon={<CloudDownload />} color="primary" variant="contained"
                    >
                        Baixar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </>
}