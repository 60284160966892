import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, CircularProgress } from '@material-ui/core';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import { makeStyles } from '@material-ui/core/styles';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  confirmButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
  denyButton: {
    color: 'white',
    backgroundColor: '#d92916',
    '&:hover': {
      backgroundColor: '#f55538',
    },
  },
}));

const DepartmentTransfer = ({ ocurrence, departments, closeIt }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [confirmation, setConfirmation] = useState(false);
  const [department, setDepartment] = useState('1');
  const [inputFile, setInputFile] = useState('');
  const [inputFileData, setInputFileData] = useState(null);
  const [note, setNote] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFileChange = event => {
    setInputFileData(event.target.files[0]);
    setInputFile(event.target.files[0].name);
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    if (inputFileData) {
      setLoading({ status: true, message: 'Fazendo upload de arquivo...' });
      formData.append('anexo', inputFileData);
      formData.append('ocorrencia_id', ocurrence.ocorrencia_id);
      formData.append('file_observacao', note);
      await ocurrencesActionService.sendActionFile(formData);
    }

    setLoading({ status: true, message: 'Enviando resposta...' });

    const postData = {
      observacao: note,
      destinatario_id: department,
      ocorrencia_id: ocurrence.ocorrencia_id,
    };

    await ocurrencesActionService.sendDepartmentTransfer(postData);

    dispatch(selectOcurrence(ocurrence.ocorrencia_id));
    setLoading({ status: false, message: '' });
    sweetAlert('success', 'Sucesso', 'Ocorrência finalizada com sucesso!');
  };

  const transferForm = () => {
    return (
      <Box>
        <Box display="flex" alignItems="center" my={4}>
          <MenuOpenIcon fontSize="small" />
          <Box fontWeight={500} fontSize={12} ml={2}>
            Dados de transferência
          </Box>
        </Box>
        <Box width="100%" mb={4}>
          <FormControl fullWidth className={classes.formControlFull}>
            <InputLabel id="quick-department">Departamento</InputLabel>
            <Select
              labelId="quick-department"
              id="quick-department-input"
              value={department}
              onChange={e => setDepartment(e.target.value)}>
              {departments.map(item => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nome}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" mb={8}>
          <Box mb={4} display="flex" alignItems="center">
            <Button variant="contained" component="label">
              Anexar Arquivo
              <input
                id="quick-answer-file"
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.gif,.png,.xls,.xlsx,.csv"
              />
            </Button>
            <Box ml={2} fontWeight={700} fontSize={12}>
              {inputFile}
            </Box>
          </Box>
          <Box width={'100%'}>
            <TextField
              label="Observação"
              fullWidth
              variant="outlined"
              size="small"
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </Box>
        </Box>
        <Button classes={{ root: classes.confirmButton }} onClick={handleSubmit}>
          Salvar
        </Button>
      </Box>
    );
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<SyncAltIcon />} title="Transferir Departamentos" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      {!confirmation ? (
        <Box mb={4}>
          {/* TODO: Essa mensagem precisa ser ajustada. */}
          <Box fontWeight={500} my={8} fontSize={16} textAlign="center">
            Essa ação irá encaminhar o envio para o departamento atual, transferindo as competências para o departamento
            selecionado no próximo passo.
          </Box>

          <Box display="flex" justifyContent="center">
            <Button classes={{ root: classes.confirmButton }} onClick={() => setConfirmation(true)}>
              Continuar
            </Button>
            <Box mx={4} />
            <Button classes={{ root: classes.denyButton }} onClick={() => closeIt()}>
              Cancelar
            </Button>
          </Box>
        </Box>
      ) : (
        transferForm()
      )}
    </div>
  );
};

export default DepartmentTransfer;
