import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import MaterialTable from '@material-table/core';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import { Add, Archive, Block, Check, Delete, DoneAll, Edit, Print, Publish, Refresh, Save, Unarchive } from '@material-ui/icons';
import HistoryIcon from '@material-ui/icons/History';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ImagePreview from '../../../f5/ImagePreview';
import _ from 'lodash';
import DialogEditOrdem from './DialogEditOrdem';
import DialogHistoryOrdem from './DialogHistoryOrdem';
import DialogPrintOrdem from './DialogPrintOrdem';

interface OrderIndexProps {

}

type Status = {
    id: number,
    nome: string,
    interno: string
}

export type History = {
    message: string,
    old_status: Status,
    new_status: Status,
    user: {
        nome: string
    },
    created_at: string
}

export type Ordem = {
    id: number,
    nome: string,
    departamento: string,
    protocolo: string,
    diagnostico_inicial: string,
    solucao: string,
    pecas_trocadas: string,
    observacao_tecnica: string,
    tecnico: string,
    archived: boolean,
    tipo_id: number,
    aberto_id: number,
    atendente_id: number,
    status_id: number,
    self?: boolean,
    anexos?: {
        uuid: string
    }[],
    history?: History[],
    status?: Status,
    created_at?: string,
    aberto?: {
        nome: string
    },
    tipo?: {
        nome: string
    },
    atendentes?: {
        id: number,
        nome: string,
        pivot: {
            principal: boolean
        }
    }[]
}

export default function OrderIndex({ ...props }: OrderIndexProps) {
    const [ordens, setOrdens] = useState<Ordem[]>([]);
    const [tipos, setTipos] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [mode, setMode] = useState<'add' | 'edit' | 'view'>('add')
    const [newAnexos, setNewAnexos] = useState<any[]>([])
    const [openHistory, setOpenHistory] = useState({ open: false, id: 0 })
    const [openPrint, setOpenPrint] = useState<{ open: boolean, data: Ordem | null }>({ open: false, data: null })
    const [counters, setCounters] = useState({
        aberto: 0,
        em_andamento: 0,
        finalizado: 0,
        cancelado: 0,
    })
    const [status, setStatus] = useState<any[]>([])
    const [filter, setFilter] = useState<string>('aberto')
    const [tecnicos, setTecnicos] = useState<any[]>([])
    const [tecnicoPrincipal, setTecnicoPrincipal] = useState<any>(null)
    const [tecnicosAuxiliares, setTecnicosAuxiliares] = useState<any[]>([])
    const [editing, setEditing] = useState<Ordem>({
        id: 0,
        nome: '',
        departamento: '',
        protocolo: '',
        diagnostico_inicial: '',
        solucao: '',
        pecas_trocadas: '',
        observacao_tecnica: '',
        tecnico: '',
        archived: false,
        tipo_id: 0,
        aberto_id: 0,
        atendente_id: 0,
        status_id: 0,
    })
    const table = useRef(null)
    const auth = useSelector(({ auth }: any) => (auth.authUser ? auth.authUser.cargo.interno : null));
    const authId = useSelector(({ auth }: any) => (auth.authUser ? auth.authUser.id : null));

    console.log(authId)

    const onLoad = () => {
        if (table.current) {
            // @ts-ignore
            table.current.onQueryChange()
            onLoadCounter()
        }
        /* setLoading(true)
        axios_instance.get<{ success: boolean, data: Ordem[], message: string }>(`ordem_servico/index?status=${filter}`).then((response) => {
            let r = response.data
            if (r.success) {
                setOrdens(r.data)
            } else {
                sweetAlert('error', 'Erro', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao carregar as ordens de serviço')
        }).finally(() => {
            setLoading(false)
        }) */
    }

    const onLoadCounter = (warn = false) => {
        axios_instance.get<{ success: boolean, alert: number, counter: any, message: string, status: any[], tipos: any[], tecnicos: any[] }>(`ordem_servico/counter`).then((response) => {
            let r = response.data
            if (r.success) {
                setCounters(r.counter)
                setCounters(r.counter)
                setStatus(r.status)
                setTipos(r.tipos)
                setTecnicos(r.tecnicos)
                if (warn) {
                    if (r.alert > 0) {
                        sweetAlert('warning', 'Atenção', `Existem ${r.alert} ordens de serviço abertas para você`)
                    }
                }
            } else {
                sweetAlert('error', 'Erro', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao carregar os contadores')
        })
    }

    useEffect(() => {
        const interval = setInterval(onLoad, 60000)
        return () => clearInterval(interval)
    }, [filter])

    useEffect(() => {
        onLoadCounter(true)
    }, [])

    const onChangeStatus = (rowData: any, status: string) => {
        setSending(true)
        axios_instance.post<{ success: boolean, message: string }>(`ordem_servico/update/${rowData.id}`, {
            status: status
        }).then((response) => {
            let r = response.data
            if (r.success) {
                onLoad()
                onLoadCounter()
                sweetAlert('success', 'Sucesso', r.message)
            } else {
                sweetAlert('error', 'Erro', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao mudar o status da ordem de serviço')
        }).finally(() => {
            setSending(false)
        })
    }

    const onSave = () => {
        setSending(true)
        setOpenModal(false)
        if (!editing.nome) {
            sweetAlert('error', 'Erro', 'Nome é obrigatório', () => setOpenModal(true))
            return
        }
        if (!tecnicoPrincipal) {
            sweetAlert('error', 'Erro', 'Técnico Responsável é obrigatório', () => setOpenModal(true))
            return
        }
        if (!editing.tipo_id) {
            sweetAlert('error', 'Erro', 'Tipo é obrigatório', () => setOpenModal(true))
            return
        }
        if (!editing.diagnostico_inicial) {
            sweetAlert('error', 'Erro', 'Diagnóstico Inicial é obrigatório', () => setOpenModal(true))
            return
        }

        let formData = new FormData()
        formData.append('nome', editing.nome)
        formData.append('departamento', editing.departamento)
        //formData.append('protocolo', editing.protocolo)
        formData.append('diagnostico_inicial', editing.diagnostico_inicial)
        formData.append('solucao', editing.solucao || '')
        formData.append('pecas_trocadas', editing.pecas_trocadas || '')
        formData.append('observacao_tecnica', editing.observacao_tecnica || '')
        formData.append('tecnico', editing.tecnico)
        //formData.append('archived', editing.archived ? '1' : '0')
        formData.append('tipo_id', editing.tipo_id.toString())
        //formData.append('aberto_id', editing.aberto_id.toString())
        //formData.append('atendente_id', editing.atendente_id.toString())
        formData.append('tecnicos[]', tecnicoPrincipal)
        for (let i = 0; i < tecnicosAuxiliares.length; i++) {
            formData.append('tecnicos[]', tecnicosAuxiliares[i])
        }
        formData.append('status_id', editing.status_id.toString())
        newAnexos.forEach((anexo, index) => {
            formData.append('anexos[]', anexo)
        })
        axios_instance.post<{ success: boolean, message: string }>(`ordem_servico/save${editing.id === 0 ? '' : `/${editing.id}`}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            let r = response.data
            if (r.success) {
                onLoad()
                onLoadCounter()
                setNewAnexos([])
                sweetAlert('success', 'Sucesso', r.message)
            } else {
                sweetAlert('error', 'Erro', r.message, () => setOpenModal(true))
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao salvar a ordem de serviço', () => setOpenModal(true))
        }).finally(() => {
            setSending(false)
        })
    }

    const getActions = useCallback(() => {
        if (auth === 'tecnico') {
            return [
                {
                    icon: () => <Refresh />, tooltip: 'Atualizar', isFreeAction: true, onClick: () => {
                        onLoad();
                    }
                }, ((row: Ordem) => ({
                    icon: () => <Edit />, tooltip: 'Editar', onClick: (_: any, rowData: Ordem) => {
                        setEditing(rowData)
                        setTecnicoPrincipal(rowData?.atendentes?.find((a: any) => a.pivot.principal)?.id || null)
                        setTecnicosAuxiliares(rowData?.atendentes?.filter((a: any) => !a.pivot.principal).map((a: any) => a.id) || [])
                        setMode('edit')
                        setOpenModal(true)
                    }, disabled: row.atendentes?.filter((a: any) => a.id === authId).length === 0
                })), ((row: Ordem) => ({
                    icon: () => <Check />, tooltip: 'Em Andamento', onClick: (_: any, rowData: Ordem) => {
                        sweetAlert('warning', 'Atenção', 'Deseja realmente mudar o status desta ordem de serviço para "Em Andamento"?', () => {
                            onChangeStatus(rowData, 'em_andamento')
                        })
                    }, disabled: row.status?.interno === 'em_andamento' || row.atendentes?.filter((a: any) => a.id === authId).length === 0
                })),
            ];
        }
        return [
            {
                icon: () => <Refresh />, tooltip: 'Atualizar', isFreeAction: true, onClick: () => {
                    onLoad();
                }
            }, {
                icon: () => <Edit />, tooltip: 'Editar', onClick: (_: any, rowData: Ordem) => {
                    setEditing(rowData)
                    setTecnicoPrincipal(rowData?.atendentes?.find((a: any) => a.pivot.principal)?.id || null)
                    setTecnicosAuxiliares(rowData?.atendentes?.filter((a: any) => !a.pivot.principal).map((a: any) => a.id) || [])
                    setMode('edit')
                    setOpenModal(true)
                }
            }, {
                icon: () => <HistoryIcon />, tooltip: 'Histórico', onClick: (_: any, rowData: Ordem) => {
                    setOpenHistory({ open: true, id: rowData.id })
                }
            }, ((row: Ordem) => ({
                icon: () => <Check />, tooltip: 'Em Andamento', onClick: (_: any, rowData: Ordem) => {
                    sweetAlert('warning', 'Atenção', 'Deseja realmente mudar o status desta ordem de serviço para "Em Andamento"?', () => {
                        onChangeStatus(rowData, 'em_andamento')
                    })
                }, disabled: row.status?.interno === 'em_andamento'
            })), ((row: Ordem) => ({
                icon: () => <DoneAll />, tooltip: 'Finalizar', onClick: (_: any, rowData: Ordem) => {
                    sweetAlert('warning', 'Atenção', 'Deseja realmente finalizar esta ordem de serviço?', () => {
                        onChangeStatus(rowData, 'finalizado')
                    })
                }, disabled: row.status?.interno === 'finalizado'
            })), ((row: Ordem) => ({
                icon: () => <Block />, tooltip: 'Cancelar', onClick: (_: any, rowData: Ordem) => {
                    sweetAlert('warning', 'Atenção', 'Deseja realmente cancelar esta ordem de serviço?', () => {
                        onChangeStatus(rowData, 'cancelado')
                    })
                }, disabled: row.status?.interno === 'cancelado'
            })), ((row: Ordem) => ({
                icon: () => row.archived ? <Unarchive /> : <Archive />, tooltip: row.archived ? 'Desarquivar' : 'Arquivar',
                onClick: (_: any, rowData: Ordem) => {
                    sweetAlert('warning', 'Atenção', `Deseja realmente ${row.archived ? 'desarquivar' : 'arquivar'} esta ordem de serviço?`, () => {
                        onChangeStatus(rowData, row.archived ? 'unarchive' : 'archive')
                    })
                }
            })), {
                icon: () => <Print />, tooltip: 'Imprimir', onClick: (_: any, rowData: Ordem) => {
                    setOpenPrint({ open: true, data: rowData })
                }
            }
        ]
    }, [auth, authId])

    const onChangeFilter = (nextFilter: string) => {
        setFilter(nextFilter)
        onLoad()
    }

    // @ts-ignore
    return <PageContainer>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={3}>
                <Paper
                    style={{ backgroundColor: '#4CAF50', color: 'white', outline: filter === 'aberto' ? '2px solid black' : 'none', cursor: 'pointer' }}
                    onClick={() => onChangeFilter('aberto')}
                >
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" py={2}>
                        <Typography variant="h6">Aberto</Typography>
                        <Typography variant="h2">{counters.aberto}</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
                <Paper style={{ backgroundColor: '#FFC107', outline: filter === 'em_andamento' ? '2px solid black' : 'none', cursor: 'pointer' }}
                    onClick={() => onChangeFilter('em_andamento')}>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" py={2}>
                        <Typography variant="h6">Em Andamento</Typography>
                        <Typography variant="h2">{counters.em_andamento}</Typography>
                    </Box>
                </Paper>
            </Grid>
            {auth !== 'tecnico' ? <Grid item xs={12} md={3}>
                <Paper style={{ backgroundColor: '#2196F3', outline: filter === 'finalizado' ? '2px solid black' : 'none', cursor: 'pointer' }} onClick={() => onChangeFilter('finalizado')}>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" py={2}>
                        <Typography variant="h6">Finalizado</Typography>
                        <Typography variant="h2">{counters.finalizado}</Typography>
                    </Box>
                </Paper>
            </Grid> : null}
            {auth !== 'tecnico' ? <Grid item xs={12} md={2}>
                <Paper style={{ backgroundColor: '#F44336', color: 'white', outline: filter === 'cancelado' ? '2px solid black' : 'none', cursor: 'pointer' }} onClick={() => onChangeFilter('cancelado')}>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" py={2}>
                        <Typography variant="h6">Cancelado</Typography>
                        <Typography variant="h2">{counters.cancelado}</Typography>
                    </Box>
                </Paper>
            </Grid> : null}
            {auth !== 'tecnico' ? <Grid item xs={12} md={1}>
                <Paper style={{ backgroundColor: '#5a1814', color: 'white', outline: filter === 'archive' ? '2px solid black' : 'none', cursor: 'pointer' }} onClick={() => onChangeFilter('archive')}>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" py={2}>
                        <Archive />
                        <Typography variant="h6">Arquivo</Typography>
                    </Box>
                </Paper>
            </Grid> : null}
        </Grid>
        <Paper>
            <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Ordens de Serviço</Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true)
                        setMode('add')
                        setNewAnexos([])
                        setEditing({
                            id: 0,
                            nome: '',
                            departamento: '',
                            protocolo: 'Gerado automaticamente',
                            diagnostico_inicial: '',
                            solucao: '',
                            pecas_trocadas: '',
                            observacao_tecnica: '',
                            tecnico: '',
                            archived: false,
                            tipo_id: 0,
                            aberto_id: 0,
                            atendente_id: 0,
                            status_id: 0,
                        })
                        setTecnicoPrincipal(null)
                        setTecnicosAuxiliares([])
                    }}
                    style={{ backgroundColor: '#4caf50', color: 'white' }}
                >
                    Abrir Ordem de Serviço
                </Button>
            </Box>
            <MaterialTable
                title=""
                tableRef={table}
                options={{
                    rowStyle: (rowData: any) => {
                        return { backgroundColor: rowData.self ? '#ffb394' : '#fff' }
                    }
                }}
                columns={[
                    { title: 'Protocolo', field: 'protocolo' },
                    { title: 'Nome', field: 'nome' },
                    { title: 'Tipo', field: 'tipo.nome' },
                    { title: 'Data abertura', field: 'created_at', type: 'date' },
                    { title: 'Status', field: 'status.nome' },
                ]}
                isLoading={loading}
                data={(query: any) =>
                    new Promise<any>((resolve, reject) => {
                        let url = `ordem_servico/index?status=${filter}`;
                        url += '&per_page=' + query.pageSize;
                        url += '&page=' + (query.page + 1);
                        if (query.orderBy) {
                            url += '&order_by=' + query.orderBy.field;
                            url += '&order=' + query.orderDirection;
                        }
                        if (query.search) {
                            url += '&search=' + query.search;
                        }
                        axios_instance
                            .get<any>(url)
                            .then(response => {
                                let r = response.data;
                                if (r.success) {
                                    resolve({
                                        data: r.data.data,
                                        page: r.data.current_page - 1,
                                        totalCount: r.data.total,
                                    });
                                }
                            })
                            .catch(() => {
                                reject();
                            });
                    })}
                components={{
                    Container: props => <Paper {...props} elevation={0} />,
                }}
                onRowClick={(_, rowData) => {
                    if (rowData) {
                        setEditing(rowData)
                        setTecnicoPrincipal(rowData?.atendentes?.find((a: any) => a.pivot.principal)?.id || null)
                        setTecnicosAuxiliares(rowData?.atendentes?.filter((a: any) => !a.pivot.principal).map((a: any) => a.id) || [])
                        setMode('view')
                        setOpenModal(true)
                    }
                }}
                // @ts-ignore
                actions={getActions()}
                localization={tableLocalization}
                icons={tableIcons}
            />
        </Paper>
        <Backdrop open={sending} style={{ zIndex: 9999 }}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" color="white">
                <CircularProgress />
                <Typography variant="h6" style={{ marginLeft: 10 }}>Enviando...</Typography>
            </Box>
        </Backdrop>
        <DialogEditOrdem
            open={openModal}
            onClose={() => {
                setOpenModal(false)
                setNewAnexos([])
            }}
            editing={editing}
            onChange={setEditing}
            onSave={onSave}
            mode={mode}
            tipos={tipos}
            status={status}
            tecnicos={tecnicos}
            anexos={newAnexos}
            onChangeAnexos={setNewAnexos}
            tecnicoPrincipal={tecnicoPrincipal}
            setTecnicoPrincipal={setTecnicoPrincipal}
            tecnicosAuxiliares={tecnicosAuxiliares}
            setTecnicosAuxiliares={setTecnicosAuxiliares}
        />
        <DialogHistoryOrdem
            open={openHistory.open}
            onClose={() => setOpenHistory({ open: false, id: 0 })}
            id={openHistory.id}
        />
        <DialogPrintOrdem
            open={openPrint.open}
            onClose={() => setOpenPrint({ open: false, data: null })}
            ordem={openPrint.data}
        />
    </PageContainer>
}