import React, { useState } from 'react';
import { Box, Button, TextField, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import { makeStyles } from '@material-ui/core/styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';

import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import sweetAlert from '../../../../f5/utils/sweetAlert';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

const AttachFiles = ({ ocurrence }) => {
  const [inputFile, setInputFile] = useState('');
  const [inputFileData, setInputFileData] = useState(null);
  const [loading, setLoading] = useState({ message: '', status: false });
  const [note, setNote] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFileChange = event => {
    setInputFileData(event.target.files[0]);
    setInputFile(event.target.files[0].name);
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    if (inputFileData) {
      setLoading({ status: true, message: 'Fazendo upload de arquivo...' });
      formData.append('anexo', inputFileData);
      formData.append('ocorrencia_id', ocurrence.ocorrencia_id);
      formData.append('file_observacao', note);
      await ocurrencesActionService.sendActionFile(formData);
    }

    dispatch(selectOcurrence(ocurrence.ocorrencia_id));
    setLoading({ status: false, message: '' });
    sweetAlert('success', 'Sucesso', 'Arquivo anexado com sucesso!');
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<CreateNewFolderIcon />} title="Anexar Arquivos" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%">
        <Box ml={2} display="flex" flexDirection="column" width="100%">
          <Box mb={4} display="flex" alignItems="center">
            <Button variant="contained" component="label">
              Anexar Arquivo
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.gif,.png,.xls,.xlsx,.csv"
              />
            </Button>
            <Box ml={2} fontWeight={700} fontSize={12}>
              {inputFile}
            </Box>
          </Box>
          <Box width={'100%'}>
            <TextField
              label="Descrição do anexo"
              fullWidth
              variant="outlined"
              size="small"
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} disabled={!inputFileData} onClick={handleSubmit}>
          salvar
        </Button>
      </Box>
    </div>
  );
};

export default AttachFiles;
