import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import {
    Box,
    CircularProgress,
    TextField,
    Button,
    Typography,
    Divider,
    FormControlLabel,
    Checkbox,
    DialogActions,
} from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import FeedbackAlert from './FeedbackAlert';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    saveButton: {
        color: 'white',
        backgroundColor: '#21cc37',
        '&:hover': {
            backgroundColor: '#32e33b',
        },
    },
}));

const AttendOcurrence = ({ ocurrence }) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [loading, setLoading] = useState({ message: '', status: false });
    const [inputFile, setInputFile] = useState('');
    const [inputFileData, setInputFileData] = useState(null);
    const [solution, setSolution] = useState('');
    const [final, setFinal] = useState('');
    const [sameAsFinal, setSameAsFinal] = useState(true);
    const [note, setNote] = useState('');

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleFileChange = event => {
        setInputFileData(event.target.files[0]);
        setInputFile(event.target.files[0].name);
    };

    // TODO: Melhorar validações de formulário
    const validateForm = () => {
        if (!final) {
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            let formData = new FormData();
            if (inputFileData) {
                setLoading({ status: true, message: 'Fazendo upload de arquivo...' });
                formData.append('anexo', inputFileData);
                formData.append('ocorrencia_id', ocurrence.ocorrencia_id);
                formData.append('file_observacao', note);
                await ocurrencesActionService.sendActionFile(formData);
            }

            setLoading({ status: true, message: 'Enviando solução...' });

            const postData = {
                observacao: note,
                descricao: sameAsFinal ? final : solution,
                solucao: final,
                ocorrencia_id: ocurrence.ocorrencia_id,
            };

            await ocurrencesActionService.sendAttendOcurrence(postData);

            dispatch(selectOcurrence(ocurrence.ocorrencia_id));
            setLoading({ status: false, message: '' });
            sweetAlert('success', 'Sucesso', 'Solução enviada com sucesso!');
        } else {
            setAlertOpen(true);
            setLoading({ status: false, message: '' });
        }
    };

    if (loading.status) {
        return (
            <Box
                className={classes.root}
                height={500}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <CircularProgress />
                <Box mt={4} fontWeight={700}>
                    {loading.message}
                </Box>
            </Box>
        );
    }

    return <div className={classes.root}>
        <SharedTitle icon={<AssignmentTurnedInIcon />} title="Responder Ocorrência" />
        <SharedDescription
            requester={ocurrence.solicitante.nome}
            date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
            service={ocurrence.servico}
            protocol={ocurrence.protocolo}
        />
        <Box width="100%" mb={4}>
            <Typography color="error">Essa mensagem será enviada ao munícipe</Typography>
            <TextField
                label="Solução"
                required
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                value={final}
                onChange={e => setFinal(e.target.value)}
            />
        </Box>
        <Divider />
        <Box width="100%" mb={4} mt={3}>
            <Typography color="error">Essa mensagem só é vista pela ouvidoria</Typography>
            <FormControlLabel
                control={<Checkbox checked={sameAsFinal} onChange={e => setSameAsFinal(e.target.checked)} />}
                label="Mesma que a solução"
            />
            <TextField
                label="Comentários"
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                value={sameAsFinal ? final : solution}
                disabled={sameAsFinal}
                onChange={e => setSolution(e.target.value)}
            />
        </Box>
        <Box ml={2} display="flex" flexDirection="column" width="100%">
            <Box mb={4} display="flex" alignItems="center">
                <Button variant="contained" component="label">
                    Anexar Arquivo
                    <input
                        id="quick-answer-file"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.gif,.png,.xls,.xlsx,.csv"
                    />
                </Button>
                <Box ml={2} fontWeight={700} fontSize={12}>
                    {inputFile}
                </Box>
            </Box>
            <Box width={'100%'}>
                <TextField
                    label="Observação"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </Box>
        </Box>
        <DialogActions>
            <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
                Responder
            </Button>
        </DialogActions>
        <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
};

export default AttendOcurrence;
