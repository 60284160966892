import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';

import AccountTabs from './AccountTabs';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';
import { AuhMethods } from '../../services/auth';

const AccountPage = () => {
    const user = useSelector(({ auth }) => (auth.authUser ? auth.authUser : null));

    const dispatch = useDispatch();

    const updateUser = () => {
        dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
    };

    if (!user) {
        return (
            <Box width="100%" height={150} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Box mt={4} fontWeight={500}>
                    Carregando dados de usuário...
                </Box>
            </Box>
        );
    }

    return <AccountTabs data={user} updateUser={updateUser} />;
};

export default AccountPage;
