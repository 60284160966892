import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FindReplaceIcon from '@material-ui/icons/FindReplace';

import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import sweetAlert from '../../../../f5/utils/sweetAlert';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    saveButton: {
        color: 'white',
        backgroundColor: '#21cc37',
        '&:hover': {
            backgroundColor: '#32e33b',
        },
    },
}));

const AnalysisOcurrence = ({ ocurrence }) => {
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState({ message: '', status: false });

    const dispatch = useDispatch();
    const classes = useStyles();

    const handleSubmit = async () => {
        setLoading({ status: true, message: 'Atualizando ocorrência...' });

        const postData = {
            observacao: note,
            ocorrencia_id: ocurrence.ocorrencia_id,
        };

        await ocurrencesActionService.sendAnalysisOcurrence(postData);

        dispatch(selectOcurrence(ocurrence.ocorrencia_id));
        setLoading({ status: false, message: '' });
        sweetAlert('success', 'Sucesso', 'Ocorrência posta em análise com sucesso!');
    };

    if (loading.status) {
        return (
            <Box
                className={classes.root}
                height={500}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <CircularProgress />
                <Box mt={4} fontWeight={700}>
                    {loading.message}
                </Box>
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <SharedTitle icon={<FindReplaceIcon />} title="Colocar Ocorrência em Análise" />
            <SharedDescription
                requester={ocurrence.solicitante.nome}
                date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
                service={ocurrence.servico}
                protocol={ocurrence.protocolo}
            />

            <Box width="100%">
                <TextField
                    label="Observação"
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </Box>
            <Box mt={2} px={4} display="flex" justifyContent="end">
                <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
                    enviar
                </Button>
            </Box>
        </div>
    );
};

export default AnalysisOcurrence;
