import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';

import Search from './Pages/Search';
import Ocurrences from './Pages/Ocurrences/General';
import Received from './Pages/Ocurrences/Received';
import Pending from './Pages/Ocurrences/Pending';
import Ongoing from './Pages/Ocurrences/Ongoing';
import Due from './Pages/Ocurrences/Due';
import Overdue from './Pages/Ocurrences/Overdue';
import Solved from './Pages/Ocurrences/Solved';
import Completed from './Pages/Ocurrences/Completed';
import ViewOcurrence from './Pages/Ocurrences/ViewOcurrence';
import CreateOcurrence from './Pages/Ocurrences/CreateOcurrence';
import Appealed from './Pages/Ocurrences/Appealed';
import Communication from './Pages/Communication';
import DepartmentOmbudsman from './Pages/DepartmentOmbudsman';
import Account from './Pages/Account';
import SubdepartmentOcurrences from './Pages/SubdepartmentOcurrences';
import Category from './Pages/Configuration/Category';
import Sector from './Pages/Configuration/Sector';
import Entry from './Pages/Configuration/Entry';
import Gravity from './Pages/Configuration/Gravity';
import Theme from './Pages/Configuration/Theme';
import Subject from './Pages/Configuration/Subject';
import Service from './Pages/Configuration/Service';
import Report from './Pages/Report';
import Archived from './Pages/Ocurrences/Archived';
import IndexLocais from './Pages/aula/IndexLocais';
import IndexAgendamento from './Pages/Schedule/IndexAgendamento';
import Agendas from './Pages/Schedule/Agendas';
import NovaAgenda from './Pages/Schedule/NovaAgenda';
import CursoList from './Pages/Curso/CursoList';
import InscricoesList from './Pages/Curso/InscricoesList';
import ServiceList from './Pages/Services/ServiceList';
import AuxlioTransporte from './Pages/Auxilio/AuxlioTransporte';
import PeriodoAuxilio from './Pages/Auxilio/PeriodoAuxilio';
import LastLogin from './Pages/Account/LastLogin';
import ObrasIndex from './Pages/Obra/ObrasIndex';
import OrderIndex from './Pages/ServiceOrder/OrderIndex';
import DepartamentIndex from './Pages/Configuration/DepartamentIndex';
import AtendenteIndex from './Pages/Configuration/AtendenteIndex';
import TipoIndex from './Pages/ServiceOrder/TipoIndex';

const RestrictedRoute = ({ accessLevel, isSector = false, component: Component, ...rest }) => {
    const { authUser } = useSelector(({ auth }) => auth);
    const location = useLocation();
    const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
    const userOffice = useSelector(({ department_ombudsman }) => department_ombudsman.office);

    if (location.pathname !== '/nova' && location.pathname !== '/conta' && userAccess === 'ouv_cadastro') {
        return <Redirect to={'/nova'} />;
    }

    if (!userOffice && !isSector && ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)) {
        return <Redirect to={'/setores'} />;
    }

    if (isSector && !['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)) {
        return <Redirect to={'/ocorrencias'} />;
    }

    if (accessLevel && !accessLevel.includes(userAccess)) {
        return authUser ? <Redirect to={'/ocorrencias'} /> : <Redirect to={'/signin'} />;
    }

    return (
        <Route
            {...rest}
            render={props =>
                authUser ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

const Routes = () => {
    const { authUser } = useSelector(({ auth }) => auth);
    const location = useLocation();

    if (location.pathname === '' || location.pathname === '/') {
        if (authUser && authUser?.cargo?.interno === 'tecnico' || authUser?.cargo?.interno === 'supervisor_tecnico') {
            return <Redirect to={'/ordem-servico/index'} />;
        }
        return <Redirect to={'/ocorrencias'} />;
    } else if (authUser && location.pathname === '/signin') {
        if (authUser?.cargo?.interno === 'tecnico' || authUser?.cargo?.interno === 'supervisor_tecnico') {
            return <Redirect to={'/ordem-servico/index'} />;
        }
        return <Redirect to={'/ocorrencias'} />;
    }

    return (
        <React.Fragment>
            <Switch>
                <RestrictedRoute path="/conta" component={Account} />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                        'ouv_cadastro',
                    ]}
                    path="/nova"
                    component={CreateOcurrence}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                        'ouv_master',
                    ]}
                    path="/pesquisar"
                    component={Search}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                        'ouv_master',
                    ]}
                    path="/ocorrencias/ver/:id"
                    component={ViewOcurrence}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                    ]}
                    path="/comunique"
                    component={Communication}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                    ]}
                    path="/ocorrencias/recebidas"
                    component={Received}
                />
                <RestrictedRoute
                    accessLevel={['admin', 'triagem', 'ouvidor']}
                    path="/ocorrencias/pendentes"
                    component={Pending}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                    ]}
                    path="/ocorrencias/andamento"
                    component={Ongoing}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                    ]}
                    path="/ocorrencias/vencer"
                    component={Due}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                    ]}
                    path="/ocorrencias/vencidas"
                    component={Overdue}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                    ]}
                    path="/ocorrencias/respondidas"
                    component={Solved}
                />
                <RestrictedRoute accessLevel={['admin', 'ouvidor']} path="/contas/last/login" component={LastLogin} />
                <RestrictedRoute accessLevel={['admin', 'ouvidor']} path="/ocorrencias/arquivadas" component={Archived} />
                <RestrictedRoute
                    accessLevel={['admin', 'triagem', 'ouvidor']}
                    path="/ocorrencias/finalizadas"
                    component={Completed}
                />
                <RestrictedRoute
                    accessLevel={['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
                    path="/ocorrencias/subdepartamento/:id"
                    component={SubdepartmentOcurrences}
                />
                <RestrictedRoute accessLevel={['admin', 'ouvidor']} path="/ocorrencias/recurso" component={Appealed} />
                <RestrictedRoute
                    accessLevel={['admin', 'ouv_master', 'ouvidor', 'gestao']}
                    path="/relatorios/:mode"
                    component={Report}
                />
                <RestrictedRoute
                    accessLevel={['admin', 'ouvidor', 'ouv_master']}
                    path="/ocorrencias/recurso"
                    component={Appealed}
                />
                <RestrictedRoute
                    accessLevel={[
                        'admin',
                        'triagem',
                        'ouvidor',
                        'gestao',
                        'funcionario',
                        'departamento',
                        'sub_departamento',
                        'ouv_master',
                    ]}
                    path="/ocorrencias"
                    component={Ocurrences}
                />
                <RestrictedRoute path="/setores" isSector={true} component={DepartmentOmbudsman} />

                <RestrictedRoute path="/configuracoes/categoria" accessLevel={['ouv_master', 'admin']} component={Category} />
                <RestrictedRoute path="/configuracoes/setor" accessLevel={['ouv_master', 'admin']} component={Sector} />
                <RestrictedRoute path="/configuracoes/entrada" accessLevel={['ouv_master', 'admin']} component={Entry} />
                <RestrictedRoute path="/configuracoes/gravidade" accessLevel={['ouv_master', 'admin']} component={Gravity} />
                <RestrictedRoute path="/configuracoes/tema" accessLevel={['ouv_master', 'admin']} component={Theme} />
                <RestrictedRoute path="/configuracoes/assunto" accessLevel={['ouv_master', 'admin']} component={Subject} />
                <RestrictedRoute path="/configuracoes/servico" accessLevel={['ouv_master', 'admin']} component={Service} />
                <RestrictedRoute path="/configuracoes/departamento" accessLevel={['ouv_master', 'admin', 'supervisor_tecnico']} component={DepartamentIndex} />
                <RestrictedRoute path="/configuracoes/usuario" accessLevel={['ouv_master', 'admin', 'supervisor_tecnico']} component={AtendenteIndex} />

                <Route path="/inscricao/:mode/:id" component={IndexLocais} />
                <Route path="/agendamento/gerenciar/:mode/:local" component={IndexAgendamento} />
                <Route path="/agendamento/calendario/:mode/:local" component={Agendas} />
                <Route path="/agendamento/agenda/nova/:mode/:local" component={NovaAgenda} />
                <Route path="/obras/index" component={ObrasIndex} />
                <Route path="/cursos/inscricoes/:id" component={InscricoesList} />
                <Route path="/cursos/index" component={CursoList} />
                <RestrictedRoute path="/ordem-servico/index" component={OrderIndex} accessLevel={['admin', 'ouv_master', 'supervisor_tecnico', 'tecnico']} />
                <RestrictedRoute path="/ordem-servico/tipo" component={TipoIndex} accessLevel={['admin', 'ouv_master', 'supervisor_tecnico']} />
                <Route path="/auxilio/transporte/:id" component={AuxlioTransporte} />
                <Route path="/auxilio/periodos" component={PeriodoAuxilio} />
                <Route path="/servicos/index" component={ServiceList} />
                <Route path="/signin" component={Login} />
                <Route path="/signup" component={Register} />
                <Route path="/forgot-password" component={ForgotPasswordPage} />
                <Redirect from="*" to="/conta" />
            </Switch>
        </React.Fragment>
    );
};

export default Routes;
