import React from 'react';
import { Box, Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
     modalRoot: {
          position: 'absolute',
          padding: theme.spacing(4),
          backgroundColor: '#f7f7f7',
          borderRadius: '0.5rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '75%',
          maxHeight: '90vh',
     },
     closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: 'rgba(255, 0, 0, 0.75)',
     },
}));

const ActionsModal = ({ open, handleClose, children }) => {
     const classes = useStyles();

     return <Modal open={open} onClose={handleClose}>
          <Box className={classes.modalRoot}>
               <IconButton className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
               </IconButton>
               {children}
          </Box>
     </Modal>
};

export default ActionsModal;
