import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import ImagePreview from '../../../f5/ImagePreview';
import { Delete, Publish, Save } from '@material-ui/icons';
import { Ordem } from './OrderIndex';

interface DialogEditOrdemProps {
    open: boolean,
    onClose: () => void,
    editing: Ordem,
    onChange: (e: Ordem) => void,
    mode: 'add' | 'edit' | 'view',
    tipos: any[],
    tecnicos: any[],
    onSave: () => void,
    status: any[],
    anexos: any[],
    onChangeAnexos: (e: any) => void,
    tecnicoPrincipal: any,
    setTecnicoPrincipal: any,
    tecnicosAuxiliares: any,
    setTecnicosAuxiliares: any
}

export default function DialogEditOrdem({
    mode, editing, onChange, onClose, open, tipos, tecnicos, onSave, status, anexos, onChangeAnexos,
    tecnicoPrincipal, setTecnicoPrincipal, tecnicosAuxiliares, setTecnicosAuxiliares,
    ...props }: DialogEditOrdemProps) {
    const theme = useTheme()
    const small = useMediaQuery(theme.breakpoints.down('sm'))
    const anexoInput = React.createRef<HTMLInputElement>()

    return <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth fullScreen={small}>
        <DialogTitle>
            {mode === 'add' ? 'Adicionar' : 'Editar'} Ordem de Serviço
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Nome do Solicitante"
                        fullWidth
                        required
                        variant="outlined"
                        value={editing.nome}
                        disabled={mode === 'view'}
                        onChange={(e) => onChange({ ...editing, nome: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Departamento do Solicitante"
                        fullWidth
                        required
                        variant="outlined"
                        value={editing.departamento}
                        disabled={mode === 'view'}
                        onChange={(e) => onChange({ ...editing, departamento: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth required>
                        <InputLabel required>Tipo de Serviço</InputLabel>
                        <Select
                            value={editing.tipo_id}
                            //@ts-ignore
                            onChange={(e) => onChange({ ...editing, tipo_id: e.target.value })}
                            label="Tipo de Serviço"
                            required
                            disabled={mode === 'view'}
                        >
                            {tipos.map((t, index) => (
                                <MenuItem key={index} value={t.id}>{t.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Protocolo"
                        fullWidth
                        variant="outlined"
                        disabled={true}
                        value={editing.protocolo}
                        onChange={(e) => onChange({ ...editing, protocolo: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth required>
                        <InputLabel required>Técnico Responsável</InputLabel>
                        <Select
                            value={tecnicoPrincipal}
                            required
                            //@ts-ignore
                            onChange={(e) => setTecnicoPrincipal(e.target.value)}
                            label="Técnico Responsável"
                            disabled={mode === 'view'}
                        >
                            {tecnicos.map((t, index) => (
                                <MenuItem key={index} value={t.id}>{t.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Técnicos Auxiliares</InputLabel>
                        <Select
                            multiple
                            value={tecnicosAuxiliares}
                            //@ts-ignore
                            onChange={(e) => setTecnicosAuxiliares(e.target.value)}
                            label="Técnicos Auxiliares"
                            disabled={mode === 'view'}
                        >
                            {tecnicos.map((t, index) => (
                                <MenuItem key={index} value={t.id}>{t.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                <TextField
                    label="Técnico Responsável"
                    fullWidth
                    variant="outlined"
                    value={editing.tecnico}
                    disabled={mode === 'view'}
                    onChange={(e) => setEditing({ ...editing, tecnico: e.target.value })}
                />
            </Grid> */}
                <Grid item xs={12}>
                    <TextField
                        label="Diagnóstico Inicial"
                        fullWidth
                        variant="outlined"
                        multiline
                        required
                        minRows={3}
                        disabled={mode === 'edit' || mode === 'view'}
                        value={editing.diagnostico_inicial}
                        onChange={(e) => onChange({ ...editing, diagnostico_inicial: e.target.value })}
                    />
                </Grid>
                {mode !== 'add' ? <Grid item xs={12}>
                    <TextField
                        label="Solução"
                        fullWidth
                        variant="outlined"
                        multiline
                        minRows={3}
                        value={editing.solucao}
                        disabled={mode === 'view'}
                        onChange={(e) => onChange({ ...editing, solucao: e.target.value })}
                    />
                </Grid> : null}
                {mode !== 'add' ? <Grid item xs={12} md={6}>
                    <TextField
                        label="Peças Trocadas"
                        fullWidth
                        variant="outlined"
                        multiline
                        minRows={3}
                        value={editing.pecas_trocadas}
                        disabled={mode === 'view'}
                        onChange={(e) => onChange({ ...editing, pecas_trocadas: e.target.value })}
                    />
                </Grid> : null}
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Observação Técnica"
                        fullWidth
                        multiline
                        variant="outlined"
                        minRows={3}
                        value={editing.observacao_tecnica}
                        disabled={mode === 'view'}
                        onChange={(e) => onChange({ ...editing, observacao_tecnica: e.target.value })}
                    />
                </Grid>
                {mode !== 'add' ? <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={editing.status_id}
                            //@ts-ignore
                            onChange={(e) => onChange({ ...editing, status_id: e.target.value })}
                            label="Status"
                            disabled={mode === 'view'}
                        >
                            {status.map((s, index) => (
                                <MenuItem key={index} value={s.id}>{s.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> : null}
                {editing?.anexos?.map((anexo: any, index: number) => <Grid item xs={12} md={2}>
                    <Box border="1px solid grey" flexDirection="column" bgcolor="#fefefe" borderRadius={5} position="relative" display="flex" justifyContent="center" alignItems="center" minHeight={100}>
                        <ImagePreview
                            url={`https://ribasdigital.cidadeinteligente.app.br/api/ordem_servico/anexo/${anexo.uuid}`}
                            label={`Anexo ${index + 1} - ${editing.protocolo}`}
                        />
                    </Box>
                </Grid>)}
                {mode !== 'view' ? <>
                    {anexos.map((anexo, index) => <Grid item xs={12} md={2}>
                        <Box border="1px solid grey" flexDirection="column" bgcolor="#fefefe" borderRadius={5} position="relative" display="flex" justifyContent="center" alignItems="center" minHeight={100}>
                            <img src={URL.createObjectURL(anexo)} style={{ maxWidth: '100%', maxHeight: 100 }} />
                            <Delete
                                style={{ cursor: 'pointer', position: 'absolute', top: 5, right: 5, color: 'red' }}
                                onClick={() => onChangeAnexos(anexos.filter((a, i) => i !== index))}
                            />
                        </Box>
                    </Grid>)}
                    <Grid item xs={12} md={2}>
                        <Box
                            border="2px dashed grey" flexDirection="column" bgcolor="#fefefe" borderRadius={5} display="flex" justifyContent="center" alignItems="center" minHeight={100} style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (anexoInput.current) {
                                    anexoInput.current.click()
                                }
                            }}
                            onDragOver={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                            onDrop={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                // block no image files
                                if (e.dataTransfer.files?.[0]?.type?.indexOf('image') === -1) {
                                    alert('Apenas imagens são permitidas')
                                    return
                                }
                                onChangeAnexos([...anexos, e.dataTransfer.files?.[0]])
                            }}
                        >
                            <Publish />
                            <Typography>Adicionar anexo</Typography>
                            <input ref={anexoInput} type="file" style={{ display: 'none' }} accept='image/*' onChange={(e) => onChangeAnexos([...anexos, e.target.files?.[0]])} />
                        </Box>
                    </Grid>
                </> : null}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Button variant="contained" color="secondary" onClick={() => onClose()}>Cancelar</Button>
                {mode === 'view' ? null : <Button
                    variant="contained"
                    startIcon={<Save />}
                    style={{ backgroundColor: '#4caf50', color: 'white' }} onClick={onSave}
                >
                    Salvar
                </Button>}
            </Box>
        </DialogActions>
    </Dialog>
}