import { Backdrop, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@material-ui/core';
import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { CityCenter } from '../../../f5/constants/MapData';
import { Add, AllInbox, Done, Edit, Map, Save, Work } from '@material-ui/icons';
import MaterialTable from '@material-table/core';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import axios_instance from '../../../services/auth/jwt/config';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { Autocomplete } from '@material-ui/lab';
// @ts-ignore
import MaskedInput from 'react-text-mask';

interface ObrasIndexProps {

}
// @ts-ignore
const CNPJMask = props => {
  const { inputRef, ...other } = props;

  // @ts-ignore
  return (
    <MaskedInput
      {...other}
      // @ts-ignore
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
};

const containerStyle = {
  width: '100%',
  height: '60vh',
};

const actions = [
  {
    id: 'all',
    name: 'Obras',
    icon: <AllInbox />,
    size: 3,
    count: 0,
    color: "#26743b"
  },
  {
    id: 'a_iniciar',
    name: 'Obras a iniciar',
    icon: <Map />,
    size: 3,
    count: 0,
    color: "#009906"
  },
  {
    id: 'em_andamento',
    name: 'Obras em Andamento',
    icon: <Work />,
    size: 3,
    count: 0,
    color: "#FF7518"
  },
  {
    id: 'finalizadas',
    name: 'Obras Finalizadas',
    icon: <Done />,
    size: 3,
    count: 0,
    color: "#ec5247"
  }
]

export default function ObrasIndex({ ...props }: ObrasIndexProps) {
  const [editing, setEditing] = useState<{ open: boolean, editing: number | false }>({ open: false, editing: false })
  const [modo, setModo] = useState('all')
  const [data, setData] = useState({
    nome: '',
    endereco: '',
    descricao: '',
    lat: CityCenter.lat,
    lng: CityCenter.lng,
    status_id: 1,
    construtora_id: 0,
    construtora: {
      id: 0,
      nome: '',
      cnpj: '',
      cif: '',
    },
    alvara: '',
    tipo_id: 1,
    flags: [],
    data_inicio: moment().format('YYYY-MM-DD'),
    prazo: moment().format('YYYY-MM-DD'),
    data_termino: moment().format('YYYY-MM-DD'),
    valor: '',
  })
  const [obras, setObras] = useState<Array<any>>([])
  const [loading, setLoading] = useState({ is: false, message: '' })
  const [counters, setCounters] = useState({ all: 0, a_iniciar: 0, em_andamento: 0, finalizadas: 0 })
  const [status, setStatus] = useState<Array<any>>([])
  const [tipos, setTipos] = useState<Array<any>>([])
  const [flags, setFlags] = useState<Array<any>>([])
  const [construtoras, setConstrutoras] = useState<Array<any>>([])
  const table = useRef<any>()

  const onLoadParams = () => {
    axios_instance.get<{ success: boolean, status: Array<any>, tipos: Array<any>, construtoras: Array<any>, flags: Array<any> }>('/obra/params').then(response => {
      let r = response.data
      if (r.success) {
        setStatus(r.status)
        setTipos(r.tipos)
        setFlags(r.flags)
        setConstrutoras([...r.construtoras, { id: 0, nome: 'Nova Construtora', cnpj: '00.000.000/0000-00', cif: '' }])
      }
    }).catch(() => {
      sweetAlert('error', 'Erro', 'Erro ao carregar parâmetros')
    }).finally(() => {
      setLoading({ is: false, message: '' })
    })
  }

  const onLoad = () => {
    axios_instance.get<{ success: boolean, data: Array<any>, counters: any }>(`/obra/index?modo=${modo}`).then(response => {
      let r = response.data
      if (r.success) {
        setObras(r.data)
        setCounters(r.counters)
      }
    }).catch(() => {
      sweetAlert('error', 'Erro', 'Erro ao carregar Obras')
    }).finally(() => {
      setLoading({ is: false, message: '' })
    })
  }

  useEffect(() => {
    onLoadParams()
  }, [])

  useEffect(() => {
    onLoad()
  }, [modo])

  const onSave = (editingId: number | false) => {
    setEditing({ open: false, editing: false })
    setLoading({ is: true, message: 'Salvando Obra...' })
    axios_instance.post<{ success: boolean, message: string }>(`/obra/store${editingId ? `/${editingId}` : ''}`, data).then(response => {
      let r = response.data
      if (r.success) {
        sweetAlert('success', 'Sucesso', 'Obra salva com sucesso')
        setEditing({ open: false, editing: false })
        onLoad()
      } else {
        sweetAlert('error', 'Erro', r.message)
        setEditing({ open: true, editing: editingId })
      }
    }).catch(() => {
      sweetAlert('error', 'Erro', 'Erro ao salvar Obra')
      setEditing({ open: true, editing: editingId })
    }).finally(() => {
      setLoading({ is: false, message: '' })
    })
  }

  return <Box>
    <Grid container spacing={1}>
      {actions.map(item => (
        <Grid
          item
          key={item.id}
          className={`rounded-top pl-2 pr-2 pt-2`}
          //@ts-ignore
          xs={item.size}
          style={{ backgroundColor: item.id === modo ? '#FF751888' : '' }}>
          <Card
            onClick={() => {
              setModo(item.id)
            }}
            style={{ cursor: 'pointer', background: item.color }}>
            <Box display="flex" alignItems="center" p={3} color="white">
              <div className="mr-3">{item.icon}</div>
              <Box display="flex" flexDirection="column">
                {/* @ts-ignore */}
                <h1>{counters[item.id]}</h1>
                <p>{item.name}</p>
              </Box>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper style={{ padding: 2, height: '100%' }}>
          <MaterialTable
            localization={tableLocalization}
            icons={tableIcons}
            tableRef={table}
            title="Obras"
            actions={[
              {
                isFreeAction: true, onClick: () => {
                  setEditing({ open: true, editing: false })
                }, icon: () => <Add />, tooltip: 'Adicionar Obra'
              }, {
                onClick: (e, r) => {
                  setData({ ...r, flags: r.flags.map((item: any) => item.id) })
                  setEditing({ open: true, editing: r.id })
                }, icon: () => <Edit />, tooltip: 'Editar Obra'
              }
            ]}
            columns={[
              {
                field: 'nome',
                title: 'Nome'
              },
            ]}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />
            }}
            options={{
              actionsColumnIndex: -1,
            }}
            data={obras/* (query: any) =>
              new Promise<any>((resolve, reject) => {
                let url = `obras/index`;
                url += '?per_page=' + query.pageSize;
                url += '&page=' + (query.page + 1);
                if (query.orderBy) {
                  url += '&order_by=' + query.orderBy.field;
                  url += '&order=' + query.orderDirection;
                }
                if (query.search) {
                  url += '&search=' + query.search;
                }
                axios_instance.get<any>(url)
                  .then(response => {
                    let r = response.data;
                    if (r.success) {
                      resolve({
                        data: r.data.data,
                        page: r.data.current_page - 1,
                        totalCount: r.data.total,
                      });
                    }
                  })
                  .catch(() => {
                    reject();
                  });
              }) */
            }
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={CityCenter}
          zoom={13}
        >
          {obras.map((obra: any) => (
            <Marker position={{ lat: obra.lat, lng: obra.lng }} />
          ))}
        </GoogleMap>
      </Grid>
    </Grid>
    <Dialog open={editing.open} onClose={() => {
      setEditing({ open: false, editing: false })
    }} fullWidth maxWidth="lg">
      <DialogTitle>
        {editing.editing ? 'Editar' : 'Adicionar'} Obra
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              label='Nome da Obra'
              fullWidth
              margin="dense"
              value={data.nome}
              onChange={e => setData({ ...data, nome: e.target.value })}
            />
            <TextField
              variant='outlined'
              label='Endereço'
              fullWidth
              margin="dense"
              value={data.endereco}
              onChange={e => setData({ ...data, endereco: e.target.value })}
            />
            <TextField
              variant='outlined'
              label='Descrição'
              fullWidth
              margin="dense"
              multiline
              minRows={3}
              value={data.descricao}
              onChange={e => setData({ ...data, descricao: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '30vh' }}
              center={CityCenter}
              zoom={13}
              onClick={(e) => {
                setData({ ...data, lat: e.latLng?.lat() ?? CityCenter.lat, lng: e.latLng?.lng() ?? CityCenter.lng })
              }}
            >
              <Marker position={{ lat: data.lat, lng: data.lng }} />
            </GoogleMap>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold' }}>Informações Adicionais</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={data.construtora}
              options={construtoras}
              getOptionSelected={(option, value) => option.id === value?.id}
              getOptionLabel={(option) => `${option.nome} (${option.cnpj})`}
              onChange={(e, v) => {
                setData({ ...data, construtora_id: v?.id ?? 0, construtora: v })
              }}
              noOptionsText="Criar nova Construtora"
              renderInput={(params) => <TextField {...params} label="Construtora" variant="outlined" margin="dense" fullWidth />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='Nome da Construtora'
              fullWidth
              margin="dense"
              value={data.construtora.nome}
              onChange={e => setData({ ...data, construtora: { ...data.construtora, nome: e.target.value } })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='CNPJ da Construtora'
              fullWidth
              margin="dense"
              value={data.construtora.cnpj}
              onChange={e => setData({ ...data, construtora: { ...data.construtora, cnpj: e.target.value } })}
              InputProps={{
                inputComponent: CNPJMask,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='Nº Inscrição CIF/CCIR'
              fullWidth
              margin="dense"
              value={data.construtora.cif}
              onChange={e => setData({ ...data, construtora: { ...data.construtora, cif: e.target.value } })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="dense" variant='outlined'>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={data.status_id}
                //@ts-ignore
                onChange={e => setData({ ...data, status_id: e.target.value })}
              >
                {status.map((item: any) => <MenuItem value={item.id} key={item.id}>{item.nome}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              label='Nº Alvará'
              fullWidth
              margin="dense"
              value={data.alvara}
              onChange={e => setData({ ...data, alvara: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth margin="dense" variant='outlined'>
              <InputLabel>Tipo de Obra</InputLabel>
              <Select
                label="Tipo de Obra"
                value={data.tipo_id}
                //@ts-ignore
                onChange={e => setData({ ...data, tipo_id: e.target.value })}
              >
                {tipos.map((item: any) => <MenuItem value={item.id} key={item.id}>{item.nome}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          {flags.map((item: any) => <Grid item xs={3} key={item.id}>
            <FormControl fullWidth margin="dense">
              <FormControlLabel
                control={<Switch />}
                label={item.nome}
                onChange={(e: any) => {
                  let f = data.flags
                  if (e.target.checked) {
                    // @ts-ignore
                    f.push(item.id)
                  } else {
                    f = f.filter((i: any) => i !== item.id)
                  }
                  setData({ ...data, flags: f })
                }}
                // @ts-ignore
                checked={data.flags.includes(item.id)}
              />
            </FormControl>
          </Grid>)}
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin='dense'
              label='Data de Início'
              format='DD/MM/YYYY'
              fullWidth
              inputVariant='outlined'
              value={moment(data.data_inicio, 'YYYY-MM-DD').toDate()}
              onChange={e => setData({ ...data, data_inicio: moment(e).format('YYYY-MM-DD') })}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin='dense'
              label='Data Prazo para Término'
              format='DD/MM/YYYY'
              fullWidth
              inputVariant='outlined'
              value={moment(data.prazo, 'YYYY-MM-DD').toDate()}
              onChange={e => setData({ ...data, prazo: moment(e).format('YYYY-MM-DD') })}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin='dense'
              label='Data de Término'
              format='DD/MM/YYYY'
              fullWidth
              inputVariant='outlined'
              value={moment(data.data_termino, 'YYYY-MM-DD').toDate()}
              onChange={e => setData({ ...data, data_termino: moment(e).format('YYYY-MM-DD') })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              label='Valor Estimado'
              fullWidth
              margin="dense"
              InputProps={{
                startAdornment: 'R$',
              }}
              value={data.valor}
              onChange={e => setData({ ...data, valor: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave(editing.editing)} variant="contained" style={{ backgroundColor: 'green', color: 'white' }} startIcon={<Save />}>Salvar</Button>
      </DialogActions>
    </Dialog>
    <Backdrop open={loading.is} style={{ zIndex: 9999 }}>
      <CircularProgress />
    </Backdrop>
  </Box>
}