import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import ConfigTable from './ConfigTable';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
    },
    title: {
        fontSize: '2rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        padding: 1,
        borderBottom: '5px solid rgba(0, 0, 0, 0.5)',
    },
    addButton: {
        backgroundColor: 'rgba(15, 175, 15, 0.9)',
        color: 'rgba(255, 255, 255, 0.95)',
        '&:hover': {
            backgroundColor: 'rgba(10, 200, 10, 1)',
        },
    },
}));

const ConfigTablePage = ({ loading, data, columns, title, newCallback }) => {
    const classes = useStyles();

    return <Paper classes={{ root: classes.root }}>
        <Box display={'flex'} p={3} alignItems={'center'}>
            <Box className={classes.title}>{title}</Box>
            <Box flex={1}></Box>
            <Button className={classes.addButton} startIcon={<AddIcon />} onClick={newCallback}>
                Adicionar
            </Button>
        </Box>
        <Box mt={4}>
            <ConfigTable data={data} loading={loading} title={title} columns={columns} />
        </Box>
    </Paper>
};

export default ConfigTablePage;
